@import "variables";

// Mixins
.cursor-disabled() {
  cursor: not-allowed;
}

// Rules
.bootstrap-select {
  /*width: 220px\9; IE8 and below*/
  //noinspection CssShorthandPropertyValue
  width: 220px \0; /*IE9 and below*/

  // The selectpicker button
  > .btn {
    width: 100%;
    padding-right: 25px;
  }

  // Error display
  .error & .btn {
    border: 1px solid @color-red-error;
  }

  // Error display
  .control-group.error & .dropdown-toggle {
    border-color: @color-red-error;
  }

  &.fit-width {
    width: auto !important;
  }

  &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: @width-default;
  }

}

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;

  &:not([class*="col-"]) {
    width: 100%;
  }
}

// The selectpicker components
.bootstrap-select.btn-group {
  &:not(.input-group-btn),
  &[class*="col-"] {
    float: none;
    display: inline-block;
    margin-left: 0;
  }

  // Forces the pull to the right, if necessary
  &,
  &[class*="col-"],
  .row-fluid &[class*="col-"] {
    &.dropdown-menu-right {
      float: right;
    }
  }

  .form-search &,
  .form-inline &,
  .form-horizontal &,
  .form-group & {
    margin-bottom: 0;
  }

  .form-group-lg  &.form-control,
  .form-group-sm  &.form-control {
    padding: 0;
  }

  // Set the width of the live search (and any other form control within an inline form)
  // see https://github.com/silviomoreto/bootstrap-select/issues/685
  .form-inline & .form-control {
    width: 100%;
  }

  .input-append & {
    margin-left: -1px;
  }

  .input-prepend & {
    margin-right: -1px;
  }

  > .disabled {
    .cursor-disabled();

    &:focus {
      outline: none !important;
    }
  }

  // The selectpicker button
  .btn {
    .filter-option {
      display: inline-block;
      overflow: hidden;
      width: 100%;
      text-align: left;
    }

    .caret {
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -2px;
      vertical-align: middle;
    }
  }

  &[class*="col-"] .btn {
    width: 100%;
  }

  // The selectpicker dropdown
  .dropdown-menu {
    min-width: 100%;
    z-index: @zindex-select-dropdown;
    box-sizing: border-box;

    &.inner {
      position: static;
      border: 0;
      padding: 0;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
    }

    li {
      position: relative;

      &:not(.disabled) a:hover small,
      &:not(.disabled) a:focus small,
      &.active:not(.disabled) a small {
        color: @color-blue-hover;
        color: fade(@color-blue-hover, 40%);
      }

      &.disabled a {
        .cursor-disabled();
      }

      a {
        cursor: pointer;

        &.opt {
          position: relative;
          padding-left: 2.25em;
        }

        span.check-mark {
          display: none;
        }
        span.text {
          display: inline-block;
        }
      }

      small {
        padding-left: 0.5em;
      }
    }

    .notify {
      position: absolute;
      bottom: 5px;
      width: 96%;
      margin: 0 2%;
      min-height: 26px;
      padding: 3px 5px;
      background: rgb(245, 245, 245);
      border: 1px solid rgb(227, 227, 227);
      box-shadow: inset 0 1px 1px fade(rgb(0, 0, 0), 5%);
      pointer-events: none;
      opacity: 0.9;
      box-sizing: border-box;
    }
  }

  .no-results {
    padding: 3px;
    background: #f5f5f5;
    margin: 0 5px;
  }

  &.fit-width .btn {
    .filter-option {
      position: static;
    }

    .caret {
      position: static;
      top: auto;
      margin-top: -1px;
    }
  }

  &.show-tick .dropdown-menu li {
    &.selected a span.check-mark {
      position: absolute;
      display: inline-block;
      right: 15px;
      margin-top: 5px;
    }

    a span.text {
      margin-right: 34px;
    }
  }
}

.bootstrap-select.show-menu-arrow {
  &.open > .btn {
    z-index: @zindex-select-dropdown + 1;
  }

  .dropdown-toggle {
    &:before {
      content: '';
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom-width: 7px;
      border-bottom-style: solid;
      border-bottom-color: @color-grey-arrow;
      border-bottom-color: fade(@color-grey-arrow, 20%);
      position: absolute;
      bottom: -4px;
      left: 9px;
      display: none;
    }

    &:after {
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid white;
      position: absolute;
      bottom: -4px;
      left: 10px;
      display: none;
    }
  }

  &.dropup .dropdown-toggle {
    &:before {
      bottom: auto;
      top: -3px;
      border-bottom: 0;
      border-top-width: 7px;
      border-top-style: solid;
      border-top-color: @color-grey-arrow;
      border-top-color: fade(@color-grey-arrow, 20%);
    }

    &:after {
      bottom: auto;
      top: -3px;
      border-top: 6px solid white;
      border-bottom: 0;
    }
  }

  &.pull-right .dropdown-toggle {
    &:before {
      right: 12px;
      left: auto;
    }

    &:after {
      right: 13px;
      left: auto;
    }
  }

  &.open > .dropdown-toggle {
    &:before,
    &:after {
      display: block;
    }
  }
}

.bs-searchbox,
.bs-actionsbox {
  padding: 4px 8px;
}

.bs-actionsbox {
  float: left;
  width: 100%;
  box-sizing: border-box;

  & .btn-group button {
    width: 50%;
  }
}

.bs-searchbox {
  & + .bs-actionsbox {
    padding: 0 8px 4px;
  }

  & input.form-control {
    margin-bottom: 0;
    width: 100%;
  }
}

.mobile-device {
  position: absolute;
  top: 0;
  left: 0;
  display: block !important;
  width: 100%;
  height: 100% !important;
  opacity: 0;
}
