/*
  loading-image
*/

#loading-image {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: none;
}

/*
    Text fields
*/
div.input-group-option:last-child span.input-group-addon-remove{
  display: none;
}

div.input-group-option:last-child input.form-control{
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

div.form-group-options div.input-group.input-group-option:last-of-type {
  display: block;
}

div.input-group-option span.input-group-addon-remove{
  cursor: pointer;
}

div.input-group-option{
  margin-bottom: 3px;
}

/*
    Selects
*/

div.input-group-multiple-select:last-child span.input-group-addon-remove{
  display: none;
}

div.input-group-multiple-select:last-child input.form-control{
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

div.input-group-multiple-select span.input-group-addon-remove{
  cursor: pointer;
  background: none;
  border: none;
}

div.input-group-multiple-select{
  margin-bottom: 5px;
}

.dual-list .list-group {
  height: 250px;
  overflow: auto;
  margin: 8px;
  padding-bottom: 8px;
}

.list-left li, .list-right li {
  cursor: pointer;
}

.list-arrows {
  padding-top: 100px;
}

.list-arrows button {
  margin-bottom: 20px;
}