*, button, input, i, a {
    -webkit-font-smoothing: antialiased;
}

*,
*:active,
*:hover {
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

html {
    overflow-x: ~"hidden\0/";
    -ms-overflow-style: none;
}

body {
    font-weight: 400;
    padding-top: 65px;
    .animated(fadeIn, 1000ms);
}

audio, video { 
  outline: none;
}

p {
    margin-bottom: 20px;
}

small {
    font-size: 11px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    small {
        font-size: 12px;
    }
}

/*
 * Ordered Lists
 */


.clist {
    list-style: none;
    
    & > li {
        &:before {
            font-family: 'Material Design Iconic Font';
            margin: 0 10px 0 -20px;
            vertical-align: middle;
        }
    }
    
    &.clist-angle > li:before {
        content: "\f1b6";
    }
    
    &.clist-check > li:before {
        content: "\f299";
    }
    
    &.clist-star > li:before {
        content: "\f2e5";
    }
}

 
#main {
    position: relative;
}

body {
    #content {
        padding: 30px 0;
    }
    
    &.sw-toggled {
        #content {
            @media (min-width: @screen-lg-min) {
                padding: 30px 0 30px 250px;
            }
            
            & > .container {
                @media (min-width: @screen-lg-min) {
                    width: ~"calc(100% - 30px)";
                }
            }
        }
    }
}