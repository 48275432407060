/*
 * Media - Overriding the Media object to 3.2 version in order to prevent issues like text overflow.
 */
.media {
    .clearfix();
    
    & > .pull-left {
        padding-right: 15px;
    }
    
    & > .pull-right {
        padding-left: 15px;
    }
    
    overflow: visible;
}

.media-heading {
    font-size: 14px;
    margin-bottom: 10px;
}

.media-body {
    zoom: 1;
    display: block;
    width: auto;
} 

.media-object {
    border-radius: 2px;
}

.close { 
    .opacity(0.5);
    font-weight: normal;
    text-shadow: none;

    &:hover {
        color: inherit;
        .opacity(1);
    }
}

.dl-horizontal dt {
    text-align: left;
}