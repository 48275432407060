input:active,
input:focus {
    outline: 0;
    box-shadow: none !important;
}

.form-control {
    box-shadow: none;
    border: 0;
    border-bottom: 1px solid #e0e0e0;
    
    &:active,
    &:focus {
        border-bottom-color: #b4b4b4;
    }
}


