.fw-container {
    .tab-content {
        padding: 25px 0;
    }
    
    .fw-footer {
        text-align: center;
        margin: 30px 0 0;
        width: 100%;
        border-top: 2px solid #eee;
        padding: 15px 0;
    }
}