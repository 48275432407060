.bootstrap-datetimepicker-widget {
    margin-top: 0 !important;
    
    &.top {
        .transform-origin(0 100%) !important;
    }
    
    &:before,
    &:after {
        display: none !important;
    }
    
    .picker-switch.accordion-toggle {
        
        span {
            background: #fff;
            border-radius: 0;
            
            &:hover {
                background: #fff;
            }
        }
    }
    
    .datepicker-days {
        table {
            margin-bottom: 20px;
        }
    }
    
    .timepicker {
        .md {
            font-size: 25px;
        }
    }
    
    .glyphicon {
        color: #333;
    }
    
}