.photos {
    .p-item {
        margin-bottom: 24px;
        .hover-pop(rgba(0,0,0,0.5));
        
        img {
            width: 100%;
            border-radius: 2px;
        }
    }
    
    .btn-float {
        z-index: 1;
        bottom: 40px;
        right: 40px;
        position: fixed;
    }
}