#messages-main {
    position: relative;
    .clearfix();
    
    .ms-block {
        padding: 23px 20px 0;
    }

    .ms-menu {
        position: absolute;
        left: 0;
        top: 0;
        background: #F8F8F8;
        border-right: 1px solid #EEE;
        padding-bottom: 50px;
        height: 100%;
        width: 240px;
        .transition(all);
        .transition-duration(250ms);

        @media (max-width: @screen-xs-max) {
            height: ~"calc(100% - 58px)";
            .translate3d(-240px, 58px, 0);
            .opacity(0);
            z-index: 1;

            &.toggled {
                .translate3d(0, 58px, 0);
                .opacity(1);
            }
        }
        
        .lv-item {
            padding-left: 20px;
            padding-right: 20px;
            
            &.active {
                background: #fff;
            }
            
            &:not(.active):hover {
                background: #F2F2F2;
                cursor: pointer;
            }
        }
    }
    
    .ms-body {
        @media (min-width: @screen-sm-min) {
            padding-left: 240px;
        }

        @media (max-width: @screen-xs-max) {
            overflow: hidden;
        }
    }
    
    .ms-user {        
        .clearfix();
        
        & > img {
            border-radius: 50%;
            width: 40px;
            float: left;
        }
        
        & > div {
            overflow: hidden;
            padding: 7px 5px 7px 15px;
            font-size: 11px;
        }
    }
    
    #ms-compose {
        position: fixed;
        bottom: 120px;
        z-index: 1;
        right: 30px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.14),0 4px 8px rgba(0, 0, 0, 0.28);
    }
}

#ms-menu-trigger {
    .user-select(none);
    float: left;
    margin: 1px 0 0 -7px;

    @media (min-width: @screen-sm-min) {
        display: none;
    }

    .line-wrap .line {
        background-color: #717171;
    }
}

/*
 * For Message
 */
.lv-message {
    .lv-item {
        padding: 20px;
        
        &.right {
            text-align: right;
            
            .lv-avatar {
                margin-right: 0;
                margin-left: 15px;
            }
        }
        
        &:not(.right) {
            .ms-item {
                background: @m-amber;
                color: #fff;
            }
        }
        
        &.right .ms-item {
            background: #eee;
        }
    }
}

.lv-avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    color: #FFF;
    text-align: center;
    line-height: 34px;
    font-size: 15px;
    margin-right: 15px;
    padding: 0 !important;
    text-transform: uppercase;
    
    & > img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        vertical-align: top;
    }
}

.ms-item {
    padding: 13px 19px 15px;
    border-radius: 2px;
    display: inline-block;
    
    @media (min-width: @screen-sm-min) {
        max-width: 70%;
    }
}

.ms-date {
    display: block;
    color: #B3B3B3;
    margin-top: 7px;
    
    & > i {
        font-size: 14px;
        vertical-align: bottom;
        line-height: 100%;
    }
}

.ms-reply {
    box-shadow: 0 -20px 20px -5px #FFF;
    position: relative;
    margin: 0 !important;
  
    textarea {
        width: 100%;
        font-size: 13px;
        border: 0;
        padding: 10px 8px;
        resize: none;
        height: 60px;
    }
    
    button {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        height: 100%;
        width: 60px;
        font-size: 25px;
        background: #F5F5F5;
        color: @m-blue;
        
        &:hover {
            background: #f2f2f2;
        }
    }
}