.bootstrap-select {    
    & > .selectpicker {
        background: none !important;
        border-bottom: 1px solid #e0e0e0 !important;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        
        &:before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            height: ~"calc(100% - 2px)";
            width: 30px;
            background-color: #FFF;
            background-position: right ~"calc(100% - 7px)";
            background-repeat: no-repeat;
            .img-retina('../img/select.png', '../img/select@2x.png', 12px, 12px);
            pointer-events: none;
            z-index: 5;
        }
            
        &:after {
            position: absolute;
            z-index: 3; 
            bottom: -1px;
            left: 0;
            height: 2px;
            width: 0;
            content: ""; 
            .transition(all);
            .transition-duration(300ms);
        }
        
        &:not(.disabled):after,
        &:not(.readonly):after { 
            background: @m-blue;
        }
        
        &.disabled:after,
        &.readonly:after {
            background: #ccc;
        }
    }
    
    &.open {
        & > .selectpicker:after {
            width: 100%;
        }
    }
    
    .bs-searchbox {
        padding: 0 18px;
        margin: 5px 0 10px;
        position: relative;
        
        &:before {
            position: absolute;
            left: 14px;
            top: 2px;
            width: 30px;
            height: 100%;
            content: "\f05f";
            font-family: 'Material Design Iconic Font';
            font-size: 25px;
        }
        
        input {
            padding-left: 25px;
            border: 0;
        }
    }
    
    &.btn-group {
        .dropdown-menu li a.opt {
            padding-left: 17px;
        }
    }
    
    .check-mark {
        margin-top: -5px !important;
        font-size: 19px;
        .transition(all);
        .transition-duration(200ms);
        .backface-visibility(hidden);
        .scale(0);
        display: block !important;
        position: absolute;
        top: 7px;
        right: 15px; 
    }
    
    .selected {
        .check-mark {
            .scale(1);
        }
    }
    
    .notify {
        bottom: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        border: 0 !important;
        background: @m-red !important;
        color: #fff !important;
        text-align: center;
    }
}
