/*!
 * Datetimepicker for Bootstrap 3
//! version : 4.0.0
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.bootstrap-datetimepicker-widget {
    &.dropdown-menu {
        margin: 2px 0;
        padding: 4px;
        width: 19em;

        &.timepicker-sbs {
            @media (min-width: @screen-sm-min) {
                width: 38em;
            }
            @media (min-width: @screen-md-min) {
                width: 38em;
            }
            @media (min-width: @screen-lg-min) {
                width: 38em;
            }
        }

        &:before, &:after {
            content: '';
            display: inline-block;
            position: absolute;
        }

        &.bottom {
            &:before {
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #ccc;
                border-bottom-color: rgba(0,0,0,.2);
                top: -7px;
                left: 7px;
            }

            &:after {
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid white;
                top: -6px;
                left: 8px;
            }
        } 

        &.top {
            &:before {
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-top: 7px solid #ccc;
                border-top-color: rgba(0,0,0,.2);
                bottom: -7px;
                left: 6px;
            }

            &:after {
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid white;
                bottom: -6px;
                left: 7px;
            }
        }

        &.pull-right {
            &:before {
                left: auto;
                right: 6px;
            }

            &:after {
                left: auto;
                right: 7px;
            }
        }
    }

    .list-unstyled {
        margin: 0;
    }

    a[data-action] {
        padding: 6px 0;
    }

    a[data-action]:active {
        box-shadow: none;
    }

    .timepicker-hour, .timepicker-minute, .timepicker-second {
        width: 54px;
        font-weight: bold;
        font-size: 1.2em;
        margin: 0;
    }

    button[data-action] {
        padding: 6px;
    }

    .btn[data-action="incrementHours"]::after {
      .sr-only();
      content: "Increment Hours";
    }
    .btn[data-action="incrementMinutes"]::after {
      .sr-only();
      content: "Increment Minutes";
    }
    .btn[data-action="decrementHours"]::after {
      .sr-only();
      content: "Decrement Hours";
    }
    .btn[data-action="decrementMinutes"]::after {
      .sr-only();
      content: "Decrement Minutes";
    }
    .btn[data-action="showHours"]::after {
      .sr-only();
      content: "Show Hours";
    }
    .btn[data-action="showMinutes"]::after {
      .sr-only();
      content: "Show Minutes";
    }
    .btn[data-action="togglePeriod"]::after {
      .sr-only();
      content: "Toggle AM/PM";
    }

    .picker-switch {
        text-align: center;
        &::after {
          .sr-only();
          content: "Toggle Date and Time Screens";
        }
        td {
            padding: 0;
            margin: 0;
            height: auto;
            width: auto;
            line-height: inherit;
            span {
                line-height: 2.5;
                height: 2.5em;
                width: 100%;
            }
        }
    }

    table {
        width: 100%;
        margin: 0;
    }

    td,
    th {
        text-align: center;
        border-radius: @border-radius-base;
    }

    th {
        height: 20px;
        line-height: 20px;
        width: 20px;

        &.picker-switch {
            width: 145px;
        }

        &.disabled,
        &.disabled:hover {
            background: none;
            color: @gray-light;
            cursor: not-allowed;
        }

        &.prev::after {
            .sr-only();
            content: "Previous Month";
        }
        &.next::after {
            .sr-only();
            content: "Next Month";
        }
    }

    thead tr:first-child th {
        cursor: pointer;

        &:hover {
            background: @gray-lighter;
        }
    }

    td {
        height: 54px;
        line-height: 54px;
        width: 54px;

        &.cw {
            font-size: .8em;
            height: 20px;
            line-height: 20px;
            color: @gray-light;
        }

        &.day
        {
            height: 20px;
            line-height: 20px;
            width: 20px;
        }

        &.day:hover,
        &.hour:hover,
        &.minute:hover,
        &.second:hover {
            background: @gray-lighter;
            cursor: pointer;
        }

        &.old,
        &.new {
            color: @gray-light;
        }

        &.today {
            position: relative;

            &:before {
                content: '';
                display: inline-block;
                border-left: 7px solid transparent;
                border-bottom: 7px solid @btn-primary-bg;
                border-top-color: rgba(0, 0, 0, 0.2);
                position: absolute;
                bottom: 4px;
                right: 4px;
            }
        }

        &.active,
        &.active:hover {
            background-color: @btn-primary-bg;
            color: @btn-primary-color;
            text-shadow: 0 -1px 0 rgba(0,0,0,.25);
        }

        &.active.today:before {
            border-bottom-color: #fff;
        }

        &.disabled,
        &.disabled:hover {
            background: none;
            color: @gray-light;
            cursor: not-allowed;
        }

        span {
            display: inline-block;
            width: 54px;
            height: 54px;
            line-height: 54px;
            margin: 2px 1.5px;
            cursor: pointer;
            border-radius: @border-radius-base;

            &:hover {
                background: @gray-lighter;
            }

            &.active {
                background-color: @btn-primary-bg;
                color: @btn-primary-color;
                text-shadow: 0 -1px 0 rgba(0,0,0,.25);
            }

            &.old {
                color: @gray-light;
            }

            &.disabled,
            &.disabled:hover {
                background: none;
                color: @gray-light;
                cursor: not-allowed;
            }
        }
    }

    &.usetwentyfour {
        td.hour {
            height: 27px;
            line-height: 27px;
        }
    }
}

.input-group.date {
    & .input-group-addon {
  cursor: pointer;
  }
}