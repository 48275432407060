.breadcrumb {
    border-bottom: 1px solid #E5E5E5;
    border-radius: 0;

    
    
    & > li {
        
        & > a {
            color: #A9A9A9;
            
            &:hover {
                color: @breadcrumb-active-color;
            }
        }
    }
}

body {
    &:not(.sw-toggled) {
        .breadcrumb {
            @media (min-width: @screen-sm-min) {
                padding: 10px 33px 11px;
            }
        }
    }
    
    &.sw-toggled {
        .breadcrumb {
            @media (min-width: 1199px) {
                padding: 10px 33px 11px 280px;
            }
        }
    }
}