.fileinput {
    .close {
        position: relative;
        top: 7px;
        margin-left: 10px;
        font-size: 22px;
        float: none;
    }
    
    .btn-file {
        
    }
    
    .input-group-addon {
        padding: 0 10px;
        vertical-align: middle; 
    }
    
    .fileinput-preview {
        width: 200px;
        height: 150px;
        position: relative;
        
        img {
            display: inline-block;
            vertical-align: middle;
            margin-top: -13px;
        }
        
        &:after {
            content: "";
            display: inline-block;
            vertical-align: middle;
        }
    }
} 